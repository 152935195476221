import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { catchError, mergeMap } from 'rxjs/operators';
import firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class FirebaseInterceptor implements HttpInterceptor {
  private user: firebase.User | null;

  constructor(private auth: AngularFireAuth, private _snackBar: MatSnackBar) {
    auth.authState.subscribe((user) => (this.user = user));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('assets/i18n/')) {
      return this.auth.authState.pipe(mergeMap((user) => this.addTokenToRequest(user, request, next)));
    }
    return next.handle(request);
  }

  private addTokenToRequest(user: firebase.User | null, request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!user) {
      return next.handle(request);
    }

    return from(user?.getIdToken()).pipe(
      mergeMap((token) => {
        if (token) {
          if (!request.headers.has('Authorization')) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
                timeout: '180000'
              }
            });
          }
        }
        return next.handle(request);
      })
    );
  }
}
