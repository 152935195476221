import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Campaign, Film, IdType, LineItem, Product, SegmentResponse } from '../../../model/data-services';
import { ProductDataService } from '../../../services/data/product-data-service';
import { FilmDataService } from '../../../services/data/film-data.service';
import { combineLatest, Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { serverDateFormat } from '../../../shared/constants';
import { CampaignDataService } from '../../../services/data/campaign-data-service';
import { LineItemDataService } from '../../../services/data/line-item-data.service';
import { AlertDialogComponent } from '../../common/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { SegmentService } from '../../../services/segment/segment-service';

interface FilmEdit extends Film {
  disabled: boolean;
}

interface ProductEdit extends Product {
  disabled: boolean;
}

interface CampaignEdit extends Campaign {
  disabled: boolean;
}

interface LineItemEdit extends LineItem {
  disabled: boolean;
}

export enum SegmentEditModeEnum {
  CAMPAIGN = 'CAMPAIGN',
  MEDIA = 'MEDIA'
}

@Component({
  selector: 'app-edit-segment',
  templateUrl: './edit-segment.component.html',
  styleUrls: ['./edit-segment.component.scss']
})
export class EditSegmentComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
  }

  public segmentEditMode = SegmentEditModeEnum;
  public selectedEditMode = SegmentEditModeEnum.CAMPAIGN;
  private allFilms: FilmEdit[];
  private allProducts: ProductEdit[];
  private allCampaigns: CampaignEdit[];
  private allLineItems: LineItemEdit[];

  public selectedProducts: ProductEdit[];
  public selectedFilms: FilmEdit[];
  public selectedCampaigns: CampaignEdit[];
  public selectedLineItems: LineItemEdit[];

  public selectedCampaignEnd: moment.Moment;
  public edited = false;
  filteredFilms: FilmEdit[];
  filteredProducts: ProductEdit[];
  filteredCampaigns: CampaignEdit[];
  filteredLineItems: LineItemEdit[];

  savedFilms: FilmEdit[];
  savedProducts: ProductEdit[];
  savedCampaigns: CampaignEdit[];
  savedLineItems: LineItemEdit[];
  savedCampaignEnd: moment.Moment;
  private ignoreChanges = false;
  private dataSubscription: Subscription | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { segment: SegmentResponse; segmentNames: string },
    private productDataService: ProductDataService,
    private dialog: MatDialog,
    private filmDataService: FilmDataService,
    private campaignDataService: CampaignDataService,
    private limeItemDataService: LineItemDataService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<EditSegmentComponent>,
    private segmentService: SegmentService
  ) {}

  ngOnInit() {
    this.selectedCampaignEnd = moment(this.data.segment.retargetingCampaignEnd, serverDateFormat);
    this.savedCampaignEnd = this.selectedCampaignEnd;
    if (this.data.segment.lineItems || this.data.segment.campaigns) {
      this.initLimeItemSelection();
    }

    if (this.data.segment.films) {
      this.initFilmSelection();
    }
  }

  initLimeItemSelection() {
    if (!this.data.segment.lineItems && !this.data.segment.campaigns) {
      return;
    }
    const lineItemIds = this.data.segment.lineItems?.split(',');
    const campaignIds = this.data.segment.campaigns?.split(',');
    this.dataSubscription = combineLatest([this.campaignDataService.data, this.limeItemDataService.data]).subscribe(([campaigns, lineItems]) => {
      this.allCampaigns = campaigns
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((campaign) => {
          return { ...campaign, disabled: false };
        });
      this.allLineItems = lineItems
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((lineItem) => {
          return { ...lineItem, disabled: false };
        });

      if (!lineItemIds?.length) {
        this.savedCampaigns = this.allCampaigns
          .filter((campaign) => campaignIds?.includes(campaign.id + ''))
          .map((campaign) => {
            campaign.disabled = true;
            return campaign;
          });
        this.savedLineItems = this.allLineItems
          .filter((lineItem) => campaignIds?.includes(lineItem.campaignId + ''))
          .map((lineItem) => {
            lineItem.disabled = true;
            return lineItem;
          });
      } else {
        this.savedLineItems = this.allLineItems
          .filter((limeItem) => lineItemIds?.includes(limeItem.id + ''))
          .map((limeItem) => {
            limeItem.disabled = true;
            return limeItem;
          });
        this.savedCampaigns = this.allCampaigns
          .filter((campaign) => this.savedLineItems.some((limeItem) => limeItem.campaignId === campaign.id))
          .map((lineItem) => {
            lineItem.disabled = true;
            return lineItem;
          });
      }

      this.selectedLineItems = this.savedLineItems;
      this.selectedCampaigns = this.savedCampaigns;

      this.filteredCampaigns = this.allCampaigns.filter(
        (campaign) => this.savedCampaigns[0].advertiserId === campaign.advertiserId && !this.savedCampaigns.some((savedCampaign) => savedCampaign.id === campaign.id)
      );
      this.filteredCampaigns = [...this.savedCampaigns, ...this.filteredCampaigns];

      this.filteredLineItems = this.allLineItems.filter(
        (limeItem) => this.savedCampaigns.some((campaign) => campaign.id === limeItem.campaignId) && !this.savedLineItems.some((savedLineItem) => savedLineItem.id === limeItem.id)
      );
      this.filteredLineItems = [...this.savedLineItems, ...this.filteredLineItems];
    });
  }

  initFilmSelection() {
    if (!this.data.segment.films) {
      return;
    }
    const filmIds = this.data.segment.films.split(',');
    this.dataSubscription = combineLatest([this.filmDataService.data, this.productDataService.data]).subscribe(([films, products]) => {
      this.allFilms = films
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((film) => {
          return { ...film, disabled: false };
        });
      this.allProducts = products
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((product) => {
          return { ...product, disabled: false };
        });

      this.savedFilms = this.allFilms
        .filter((film) => filmIds.includes(film.id + ''))
        .map((film) => {
          film.disabled = true;
          return film;
        });
      this.selectedFilms = this.savedFilms;

      this.savedProducts = this.allProducts
        .filter((product) => this.savedFilms.some((film) => film.productId === product.id))
        .map((product) => {
          product.disabled = true;
          return product;
        });

      this.filteredProducts = this.allProducts.filter(
        (product) => this.savedFilms[0].advertiserId === product.advertiserId && !this.savedProducts.some((savedProduct) => savedProduct.id === product.id)
      );
      this.filteredProducts = [...this.savedProducts, ...this.filteredProducts];
      this.selectedProducts = this.savedProducts;
      this.filteredFilms = this.allFilms.filter((film) => this.savedProducts.some((product) => product.id === film.productId) && !this.savedFilms.some((savedFilm) => savedFilm.id === film.id));
      this.filteredFilms = [...this.savedFilms, ...this.filteredFilms];
    });
  }

  save() {
    const editedSegment = this.data.segment;
    if (editedSegment.films) {
      editedSegment.films = this.selectedFilms.map((film) => film.id).join(',');
    }
    if (editedSegment.lineItems) {
      editedSegment.lineItems = this.selectedLineItems.map((lineItem) => lineItem.id).join(',');
    } else if (editedSegment.campaigns) {
      editedSegment.campaigns = this.selectedCampaigns.map((campaigns) => campaigns.id).join(',');
    }
    editedSegment.retargetingCampaignEnd = this.selectedCampaignEnd.format(serverDateFormat);
    this.segmentService.updateSegment(editedSegment).subscribe((result) => {
      this.dialogRef.close(result);
    });
  }

  close() {
    if (!this.edited) {
      this.dialogRef.close();
      return;
    }
    this.openConfirmationDialog()
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.dialogRef.close(null);
        }
      });
  }

  private openConfirmationDialog(cancelFn?: Function): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'alert-dialog',
      data: {
        message: 'Wollen Sie die Änderungen wirklich verwerfen?',
        buttonText: { yes: this.translateService.instant('button.discard') }
      }
    });
  }

  private revertChanges() {
    this.selectedCampaigns = this.savedCampaigns;
    this.selectedLineItems = this.savedLineItems;
    this.selectedProducts = this.savedProducts;
    this.selectedFilms = this.savedFilms;
    this.selectedCampaignEnd = this.savedCampaignEnd;
    this.edited = false;
  }

  async onEditModeChange(event: MouseEvent) {
    if (this.ignoreChanges) {
      this.ignoreChanges = false;
      this.revertChanges();
      return;
    }
    if (!this.ignoreChanges && this.edited) {
      const target = event.target as HTMLInputElement;
      event.preventDefault();
      this.openConfirmationDialog()
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.ignoreChanges = true;
            target.click();
          }
        });
    }
  }

  onDateChange() {
    this.edited = !this.savedCampaignEnd.isSame(this.selectedCampaignEnd);
  }

  onCampaignChange() {
    this.filteredLineItems = this.allLineItems.filter(
      (lineItem) => this.selectedCampaigns.some((selectedCampaign) => selectedCampaign.id === lineItem.campaignId) && !this.savedLineItems.some((savedLineItem) => savedLineItem.id === lineItem.id)
    );
    this.selectedLineItems = this.selectedLineItems.filter((lineItem) => this.selectedCampaigns.some((campaign) => campaign.id === lineItem.campaignId));
    this.filteredLineItems = [...this.savedLineItems, ...this.filteredLineItems];
    if (this.data.segment.campaigns && this.data.segment.lineItems === null) {
      this.filteredLineItems.forEach((lineItem) => (lineItem.disabled = true));
      this.selectedLineItems = this.filteredLineItems;
    }
    this.edited = this.savedLineItems.length !== this.selectedLineItems.length;
  }

  onProductChange() {
    this.filteredFilms = this.allFilms.filter(
      (film) => this.selectedProducts.some((selectedProduct) => selectedProduct.id === film.productId) && !this.savedFilms.some((savedFilm) => savedFilm.id === film.id)
    );
    this.filteredFilms = [...this.savedFilms, ...this.filteredFilms];
    this.selectedFilms = this.selectedFilms.filter((film) => this.selectedProducts.some((product) => product.id === film.productId));
    this.edited = this.savedFilms.length !== this.selectedFilms.length;
  }

  onLineItemChange() {
    this.edited = this.savedLineItems.length !== this.selectedLineItems.length;
  }

  onFilmChange() {
    this.edited = this.savedFilms.length !== this.selectedFilms.length;
  }

  public compareObject(option: any, value: any): boolean {
    return option.id === value.id;
  }
}
