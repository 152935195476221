import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from './services/auth/loading.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [LoadingService]
})
export class AppComponent implements OnInit {
  public path: string | undefined;

  constructor(private translateService: TranslateService) {
    this.translateService.use('de');
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }

  ngOnInit(): void {}
}
