import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InfoEnum, Targeting, TargetingStrategyEnum } from '../../model/targeting';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {
  public infoEnum = InfoEnum;
  public targetingStrategy = TargetingStrategyEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { infoEnum: InfoEnum; targeting: Targeting }, private dialogRef: MatDialogRef<InfoDialogComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
