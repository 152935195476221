/*
setting maintenanceMode to true will prevent users from accessing any page and redirect them to the maintenance page
https://rtldata.atlassian.net/browse/KOL-1136
*/

interface Config {
  maintenanceMode: boolean;
}

export const config: Config = {
  maintenanceMode: false
};
