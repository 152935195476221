export function getConfig(): any {
  const firebaseProd = {
    // actual PROD values, something went wrong in the past, DEV values are used

    // apiKey: 'AIzaSyDZ7tkwTFuc60isGoAdskb6HzcZRLbTgKM',
    // authDomain: 'auth.kolumbus.prod.da-service.io'

    apiKey: 'AIzaSyCLJi6MEr6ujn3bT6cfLwTaQKkbJGq3qqs',
    authDomain: 'auth.kolumbus.dev.da-service.io'
  };

  const firebasePreprod = {
    apiKey: 'AIzaSyAxeXOEMna8iYyCKAflTROGyOPulSBDq9E',
    authDomain: 'auth.kolumbus.preprod.da-service.io'
  };

  const firebaseDev = {
    apiKey: 'AIzaSyCLJi6MEr6ujn3bT6cfLwTaQKkbJGq3qqs',
    authDomain: 'auth.kolumbus.dev.da-service.io',
    projectId: 'mgr-kolumbus-dev-deal',
    storageBucket: 'mgr-kolumbus-dev-deal.appspot.com',
    messagingSenderId: '706659143629',
    appId: '1:706659143629:web:061206c26d8512ba61fe84'
  };

  const firebaseLocal = {
    ...firebaseDev,
    authDomain: 'mgr-kolumbus-dev-deal.firebaseapp.com'
  };

  const firebaseLocalPreprod = {
    ...firebasePreprod,
    authDomain: 'mgr-kolumbus-dev-deal.firebaseapp.com'
  };

  const host = location.hostname;
  if (host === 'localhost') {
    //  return firebaseLocalPreprod;
    return firebaseLocal;
  }

  if (host === 'app.kolumbus.dev.da-service.io') {
    return firebaseDev;
  }

  if (host === 'app.kolumbus.preprod.da-service.io') {
    return firebasePreprod;
  }

  if (host === 'app.kolumbus.prod.da-service.io') {
    return firebaseProd;
  }

  return firebaseDev;
}
