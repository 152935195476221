import { version } from '../../package.json';
import { getConfig } from '../assets/firebaseConfig';

export const environment = {
  production: true,
  version,
  serverUrl: 'api/',
  firebaseConfig: getConfig(),
  getProductEmail: 'VLRTLDproductscrosstarget@rtl.de'
};
