import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractSingleDataService } from './abstract-single-data.service';
import { User } from '../../model/data-services';
import API from 'src/app/model/API';

@Injectable({
  providedIn: 'root'
})
export class UserDataService extends AbstractSingleDataService<User> {
  constructor(http: HttpClient) {
    super(http, API.USER);
  }
}
