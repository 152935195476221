import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ROLE_ADMIN } from 'src/app/shared/constants';
import { UserDataService } from '../data/user-data-service';
import { config } from '../../../../config';

@Injectable()
export class AdminGuard implements CanActivate {
  maintenanceMode = config.maintenanceMode;

  constructor(private router: Router, private userDataService: UserDataService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.userDataService.data.pipe(
      map((user) => {
        const canActivate = user.roles?.includes(ROLE_ADMIN);
        if (!canActivate) {
          if (this.maintenanceMode === true) {
            this.router.navigate(['/maintenance']);
            return canActivate;
          }
          this.router.navigate(['/unauthorized']);
          return canActivate;
        }
        return canActivate;
      }),
      catchError((error) => {
        this.router.navigate(['/unauthorized']);
        return of(false);
      })
    );
  }
}
