import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SegmentResponse } from '../../../model/data-services';
import { SegmentDetailsComponent } from '../segment-details/segment-details.component';

@Component({
  selector: 'app-create-segment-confirmation',
  templateUrl: './create-segment-confirmation.component.html',
  styleUrls: ['./create-segment-confirmation.component.scss']
})
export class CreateSegmentConfirmationComponent implements OnInit {
  copied = false;
  value = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data: { segments: SegmentResponse[] }, private dialog: MatDialog) {}

  goToSegmentDetails() {
    this.dialog.open(SegmentDetailsComponent, {
      width: '1136px',
      autoFocus: false,
      data: {
        segments: this.data.segments
      }
    });
  }

  copyToClipboard() {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 1500);
  }

  ngOnInit(): void {
    let resultAsText = '';
    for (const segment of this.data.segments) {
      if (resultAsText.length) {
        resultAsText = resultAsText.concat('\n\n');
      }
      if (segment.whichUpload === 'adex' && segment.segmentType === 'dforce') {
        resultAsText = resultAsText.concat('Segmentname: ' + segment.segmentName);
      } else {
        resultAsText = resultAsText.concat('Key Value: ' + segment.keyValue);
      }
    }
    this.value = resultAsText;
  }
}
