export enum StorageKeyEnum {
  AUTHENTICATION = 'authentication',
  LOGIN_IN_PROGRESS = 'loginInProgress'
}

export const dateFormat = 'DD.MM.YYYY';
export const serverDateFormat = 'YYYY-MM-DD';
export const AD_ALLIANCE = 'Ad Alliance';
export const D_FORCE = 'D-Force';
export const INSTREAM = 'instream';
export const SPOT = 'spot';
export const ATV = 'atv';
export const DIGITAL = 'digital';
export const ALL = 'all';

export const ROLE_ADMIN = 'CROSSTARGET_ADMIN';
export const ROLE_DATA_OPERATIONS = 'CROSSTARGET_DATA_OPERATIONS';
export const ROLE_CAMPAIGN_MANAGER = 'CROSSTARGET_CAMPAIGN_MANAGER';
export const ROLE_CLIENT_SERVICES = 'CROSSTARGET_CLIENT_SERVICES';
export const ROLE_GUEST = 'CROSSTARGET_GUEST';

export const DIALOG_WIDTH = '1137px';

export const roles = [
  { id: ROLE_ADMIN, name: 'Admin' },
  { id: ROLE_DATA_OPERATIONS, name: 'Data Operations' },
  { id: ROLE_CAMPAIGN_MANAGER, name: 'Campaign Manager' },
  { id: ROLE_CLIENT_SERVICES, name: 'Client Services' },
  { id: ROLE_GUEST, name: 'Gast' }
];
