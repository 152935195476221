import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import firebase from 'firebase/app';
import { Router } from '@angular/router';
import { AuthenticationProviderEnum } from '../../model/auth';
import { StorageKeyEnum } from '../../shared/constants';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private auth: AngularFireAuth, private router: Router) {}

  getCurrentUser(): Promise<firebase.User | null> {
    return this.auth.currentUser;
  }

  getAuthentication() {
    return this.auth;
  }

  loginWithRedirect(authenticationProvider: AuthenticationProviderEnum) {
    localStorage.setItem(StorageKeyEnum.LOGIN_IN_PROGRESS, 'true');
    try {
      const provider = new firebase.auth.OAuthProvider(authenticationProvider);
      this.auth.signInWithPopup(provider).then((userCredential) => {
        if (userCredential.credential && userCredential.user) {
          localStorage.setItem(StorageKeyEnum.AUTHENTICATION, JSON.stringify(userCredential));
          localStorage.removeItem(StorageKeyEnum.LOGIN_IN_PROGRESS);
          this.router.navigate(['/targeting']);
        }
      });
    } catch (e) {
      localStorage.removeItem(StorageKeyEnum.LOGIN_IN_PROGRESS);
      console.error(e);
    }
  }

  async logoutUser() {
    try {
      await this.auth.signOut();
    } catch (e) {
      console.error(e);
    } finally {
      await this.router.navigate(['/login']);
      localStorage.removeItem(StorageKeyEnum.AUTHENTICATION);
      localStorage.removeItem(StorageKeyEnum.LOGIN_IN_PROGRESS);
    }
  }
}
