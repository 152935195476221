import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
  contactEmail = environment.getProductEmail;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }, private dialogRef: MatDialogRef<ErrorDialogComponent>) {}
  close() {
    this.dialogRef.close();
  }
  sendMail() {
    window.location.href = `mailto:${this.contactEmail}?subject=[crosstarget][error]&body=${this.data?.message ? this.data.message : ''}`;
  }
}
