import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContactClasses, PotentialResult, Relation, SegmentChangeHistory, SegmentResponse } from '../../../model/data-services';
import { AdTypeEnum, InfoEnum, Period, Targeting, TargetingStrategyEnum } from '../../../model/targeting';
import * as _ from 'lodash';
import { InfoDialogComponent } from '../../info-dialog/info-dialog.component';
import { SegmentService } from '../../../services/segment/segment-service';
import { DIALOG_WIDTH, INSTREAM, ROLE_ADMIN, ROLE_DATA_OPERATIONS } from '../../../shared/constants';
import { EditSegmentComponent } from '../edit-segment/edit-segment.component';
import { SegmentHistoryComponent } from '../segment-history/segment-history.component';
import { take } from 'rxjs/operators';
import { UserDataService } from '../../../services/data/user-data-service';
import { Clipboard } from '@angular/cdk/clipboard';

export interface SegmentDetail {
  state?: string;
  segmentNames?: string;
  whichUpload?: string;
  segmentType?: string;
  households?: number;
  impressions?: number;
  cookies?: number;
  segmentContactClasses?: ContactClasses;
  potentials?: PotentialResult;
  targetingStrategy: TargetingStrategyEnum | string;
}

export interface Mapping {
  [key: string]: string;
}

@Component({
  selector: 'app-segment-details',
  templateUrl: './segment-details.component.html',
  styleUrls: ['./segment-details.component.scss']
})
export class SegmentDetailsComponent implements OnInit {
  segment: SegmentResponse;
  infoEnum = InfoEnum;
  segmentDetail: SegmentDetail;
  canEdit: boolean;
  copied = false;
  private edited = false;
  private changeHistories: SegmentChangeHistory[];
  @Input() isInWorkflow = false;
  @Input() isCreateSegmentMode: boolean;
  mapping: Mapping = {
    con: 'Reminder',
    inc: 'Incremental',
    fre: 'Frequency'
  };

  constructor(
    private dialog: MatDialog,
    private segmentService: SegmentService,
    private dialogRef: MatDialogRef<SegmentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      segments: SegmentResponse[];
      originalSegments: SegmentResponse[];
      targeting: Targeting;
      relationData: Relation;
      period: Period;
      mode: boolean;
    },
    private userDataService: UserDataService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.isCreateSegmentMode = this.data?.mode;
    this.segmentDetail = {} as SegmentDetail;
    if (this.data.targeting) {
      this.segmentDetail.impressions = this.data.targeting.impressions;
      this.segmentDetail.cookies = this.data.targeting.cookies;
      this.segmentDetail.households = this.data.targeting.households;
      this.segmentDetail.potentials = this.data.targeting.potentials;
      this.segmentDetail.targetingStrategy = this.data.targeting.targetingStrategy;
    } else {
      this.segment = this.data.segments[0];
      this.segment.retargetingCampaignStart = this.segment?.retargetingCampaignStart;
      this.segment.retargetingCampaignEnd = this.segment?.retargetingCampaignEnd;
      this.segmentDetail.state = this.segmentService.getState(this.segment);
      this.segmentDetail.segmentNames = _.map(this.data.segments, (segment) => segment.targetingId + ' - ' + segment.segmentName).join(', ');
      this.segmentDetail.segmentContactClasses = this.segment.segmentContactClasses;
      this.segmentDetail.households = this.segment.segmentSize;
      this.segmentDetail.impressions = this.segment.segmentImpressions;
      this.segmentDetail.cookies = this.segment.segmentImpressions;
      this.segmentDetail.whichUpload = this.segment.whichUpload;
      this.segmentDetail.segmentType = this.segment.segmentType;
      this.segmentDetail.potentials = this.segment.potentials;
      this.segmentDetail.targetingStrategy = this.segment.whichCampaignType;
      this.loadHistories();
    }
    this.userDataService.data.subscribe((user) => {
      this.canEdit = user.roles.includes(ROLE_ADMIN) || user.roles.includes(ROLE_DATA_OPERATIONS);
    });
  }

  private loadHistories() {
    const segmentName = this.segmentService.getSegmentName(this.segment);
    this.segmentService
      .getSegmentChangeHistory(segmentName)
      .pipe(take(1))
      .subscribe((history) => {
        this.changeHistories = history;
      });
  }

  openInfoDialog(infoEnum: InfoEnum) {
    let targeting = this.data.targeting;
    if (!this.data.targeting) {
      targeting = {
        adType: this.data.segments[0].identifierType === INSTREAM ? AdTypeEnum.ATV_DIGITAL : AdTypeEnum.ATV,
        targetingStrategy: this.segmentService.getTargetingStrategy(this.data.segments[0].whichCampaignType)
      } as unknown as Targeting;
    }
    this.dialog.open(InfoDialogComponent, {
      width: DIALOG_WIDTH,
      data: {
        infoEnum,
        targeting
      }
    });
  }

  close() {
    this.dialogRef.close(this.edited);
  }

  openEditDialog() {
    this.dialog
      .open(EditSegmentComponent, {
        width: DIALOG_WIDTH,
        autoFocus: false,
        disableClose: true,
        hasBackdrop: true,
        panelClass: 'secondary-level-dialog',
        data: {
          segmentNames: this.segmentDetail.segmentNames,
          segment: this.data.originalSegments ? this.data.originalSegments.find((segment) => segment.segmentName === this.data.segments[0].segmentName) : this.data.segments[0]
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.edited = true;
          this.segment = result[0];
          this.segment.retargetingCampaignEnd = this.segment?.retargetingCampaignEnd;
          this.loadHistories();
        }
      });
  }

  openHistoryDialog() {
    this.dialog.open(SegmentHistoryComponent, {
      width: DIALOG_WIDTH,
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'secondary-level-dialog',
      data: {
        changeHistories: this.changeHistories
      }
    });
  }

  copyToClipboard() {
    this.copied = true;
    let content;
    if (this.segmentDetail.whichUpload === 'adex' && this.segmentDetail.segmentType === 'dforce') {
      content = _.map(this.data.segments, (segment) => 'Segmentname: ' + segment.segmentName).join('\n\n');
    } else {
      content = _.map(this.data.segments, (segment) => 'Key Value: ' + segment.keyValue).join('\n\n');
    }

    this.clipboard.copy(content);
    setTimeout(() => {
      this.copied = false;
    }, 1500);
  }
}
