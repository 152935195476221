import { AbstractDataService } from './abstract-data.service';
import { IdType, LineItem } from '../../model/data-services';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const REST_ENDPOINT_URI = 'lineitems/';

@Injectable({
  providedIn: 'root'
})
export class LineItemDataService extends AbstractDataService<LineItem> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }

  getByCampaigns(ids: number[]): Observable<LineItem[]> {
    return this.data.pipe(map((lineItems) => lineItems.filter((lineItem) => ids.includes(lineItem.campaignId))));
  }
}
