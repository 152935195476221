import { AbstractDataService } from './abstract-data.service';
import { Product } from '../../model/data-services';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const REST_ENDPOINT_URI = 'products/';

@Injectable({
  providedIn: 'root'
})
export class ProductDataService extends AbstractDataService<Product> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }
}
