import { Component, Inject, OnInit } from '@angular/core';
import { SegmentChangeHistory, TableColumns } from '../../../model/data-services';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { dateFormat } from '../../../shared/constants';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-segment-history',
  templateUrl: './segment-history.component.html',
  styleUrls: ['./segment-history.component.scss']
})
export class SegmentHistoryComponent implements OnInit {
  public data: any[];
  public columnDefs: TableColumns[] = [
    { field: 'updatedTime', name: 'segment.history.columns.updatedTime', width: 120 },
    { field: 'parameter', name: 'segment.history.columns.parameter', width: 150 },
    { field: 'oldValue', name: 'segment.history.columns.oldValue', width: 150 },
    { field: 'newValue', name: 'segment.history.columns.newValue', width: 150 },
    { field: 'updatedUser', name: 'segment.history.columns.updatedUser', width: 300 }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { changeHistories: SegmentChangeHistory[] }, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.data = this.dialogData.changeHistories.map((history) => {
      return {
        updatedTime: this.dateCellRenderer(history.updatedTime),
        parameter: this.parameterCellRenderer(history.parameter),
        oldValue: this.valueCellRenderer(history.parameter, history.oldValue),
        newValue: this.valueCellRenderer(history.parameter, history.newValue),
        updatedUser: history.updatedUser
      } as SegmentChangeHistory;
    });
    this.data = _.sortBy(this.data, 'date').reverse();
  }

  private dateCellRenderer = (value: any): string => {
    if (!value) {
      return '';
    }
    const updateDate = new Date(value);
    return moment(updateDate).format(dateFormat);
  };

  private valueCellRenderer = (parameter: any, value: any): string => {
    if ('retargetingCampaignEnd' === parameter) {
      return this.dateCellRenderer(value);
    }
    return value;
  };

  private parameterCellRenderer = (value: any): string => {
    if (!value) {
      return '';
    }
    return this.translateService.instant('targeting.' + value);
  };
}
