import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

export function observableToPromise<T>(observable: Observable<T>): Promise<T> {
  let subscription: Subscription | undefined;
  const promise = new Promise<T>((resolve, reject) => {
    subscription = observable.pipe(take(1)).subscribe(
      (value) => resolve(value),
      (error) => reject(error)
    );
  });
  promise.finally(() => subscription?.unsubscribe());
  return promise;
}
