import { AllowedAtvSpotLengthValue, Campaign, ContactClasses, Customer, Film, LineItem, PotentialResult, Product, Spot } from './data-services';

// Markierungsflight (Referenz)
export enum AdTypeEnum {
  ATV = 'ATV',
  ATV_DIGITAL = 'ATV_DIGITAL'
}

// Gattungen
export enum GenusTypeEnum {
  ATV = 'ATV',
  OLV = 'OLV',
  X_MEDIA = 'XMEDIA'
}

export enum ProductATVEnum {
  ATV_SPOT = 'atvspot',
  SWITCH_XXL_ZOOM = 'switchinXXLZoomMasthead',
  SWITCH_SPOT_BUMPER = 'switchinSpotBumper',
  ATV_SPOT_EXCLUDE_CTV = 'excludeCTV'
}

export enum ProductOLVEnum {
  OUTSTREAM = 'outstream',
  INSTREAM = 'instream',
  IN_PAGE = 'inpage',
  INSTREAM_CTV = 'instreamCTV'
}

export enum ProductXMEDIAEnum {
  X_MEDIA_FRAME = 'frame',
  X_MEDIA_SPOT = 'spot',
  X_MEDIA_BUMPER = 'bumper',
  X_MEDIA_BIG_SCREEN_BUMPER = 'bigScreenBumper',
  X_MEDIA_BIG_SCREEN_SPOT = 'bigScreenSpot'
}

export type ProductAdTypes = ProductATVEnum | ProductOLVEnum | ProductXMEDIAEnum;

export enum TargetingStrategyEnum {
  REMINDER = 'con',
  INCREMENTAL = 'inc',
  FREQUENCY = 'fre'
}

export interface Option {
  id: string;
  name: string;
}

export enum InfoEnum {
  CROSS_DEVICE_TARGETING,
  POTENTIAL_PREVIEW,
  POTENTIAL_DETAILS,
  AD_TYPES,
  CROSS_DEVICE_REACH,
  REACH_PREVIEW,
  REACH_DETAILS,
  PROBLEM_SEARCH
}

export interface Targeting {
  adType: string;
  atvSpotLength: string;
  productSelection: string;
  targetingStrategy: TargetingStrategyEnum;
  targetingType: AdTypeEnum;
  customers: Customer;
  products: Product[];
  campaigns: Campaign[];
  lineItems: LineItem[];
  films: Film[];
  retargetingDateFrom: string;
  retargetingDateTo: string;
  households?: number;
  impressions?: number;
  cookies?: number;
  contactClasses?: ContactClasses;
  frequencyContactLimit?: number;
  potentials: PotentialResult | undefined;
  standardSegmentATV: { text: string; query: string };
  standardSegmentOLV: { text: string; query: string };
  standardSegmentXMedia: { text: string; query: string };
  adTypes: ProductAdTypes[];
}

export interface StandardSegmentsLogic {
  atv: string;
  atvName: string;
  instream: string;
  instreamName: string;
  olv: string;
  olvName: string;
  xMedia: string;
  xMediaName: string;
}

export interface Period {
  from: string | null | undefined;
  to: string | null | undefined;
  hasNullValue: boolean;
}

export interface TargetingFilter {
  adType: AdTypeEnum | null;
  atvSpotLength: AllowedAtvSpotLengthValue;
  customer: Customer | null;
  products: Product[];
  films: Film[];
  campaigns: Campaign[];
  lineItems: LineItem[];
  spots: Spot[];
  targetingStrategy: TargetingStrategyEnum;
  retargetingDateFrom: Date | null;
  retargetingDateTo: Date | null;
  frequencyContactLimit: string | null;
  standardSegmentATV: string | null;
  standardSegmentOLV: string | null;
  adTypes: ProductAdTypes[];
  adTypesATV: ProductATVEnum[];
  adTypesOLV: ProductOLVEnum[];
  adTypesXMEDIA: (ProductATVEnum | ProductOLVEnum)[];
  inventory: string[];
}

export enum CreateSegmentWorkflow {
  CALCULATING,
  DETAILS,
  CREATE
}

export enum CalculatorTypeEnum {
  TARGETING,
  TARGETING_GROUP
}
