import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export abstract class AbstractDataService<T> {
  protected readonly restEndpointUrl: string;
  protected readonly dataSubject = new ReplaySubject<Array<T>>();
  public readonly data: Observable<Array<T>> = this.dataSubject.asObservable();

  protected constructor(protected http: HttpClient, protected restEndpointUri: string) {
    this.restEndpointUrl = environment.serverUrl + restEndpointUri;

    this.getData().then((data) => this.dataSubject.next(data));
  }

  private getData(): Promise<Array<T>> {
    const headers = new HttpHeaders({
      accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    return new Promise<Array<T>>((resolve, reject) => {
      this.http.get<T>(this.restEndpointUrl, { headers }).subscribe((data: any) => resolve(data)), (error: any) => reject(error);
    });
  }
}
