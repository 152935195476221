import { Component, ElementRef, EventEmitter, Host, HostListener, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Output() clickOutside = new EventEmitter<MouseEvent>();

  public openFeedbackMenu = false;
  public email: string;
  public getSendfeedbackHref: string;
  public version = environment.version;

  constructor(private fireAuth: AngularFireAuth, private elementRef: ElementRef) {
    fireAuth.authState.subscribe((user) => {
      this.email = user?.email || '';
      this.getSendfeedbackHref = `mailto:${environment.getProductEmail}?subject=[Crosstarget][Userfeedback]${user?.email}`;
    });
  }

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }
    const clickInsideMenu = this.elementRef.nativeElement.contains(targetElement);
    if (!clickInsideMenu) {
      this.openFeedbackMenu = false;
    }
  }

  toggleSubmenu() {
    this.openFeedbackMenu = !this.openFeedbackMenu;
  }
}
