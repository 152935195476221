import { NgModule } from '@angular/core';
import { HeaderComponent } from '../components/header/header.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AlertDialogComponent } from '../components/common/alert-dialog/alert-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LoadingComponent } from '../components/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SortByPipe } from '../pipes/sort-by.pipe';

@NgModule({
  declarations: [HeaderComponent, AlertDialogComponent, LoadingComponent, SortByPipe],
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule, TranslateModule, MatDialogModule, MatProgressSpinnerModule],
  exports: [HeaderComponent, LoadingComponent, SortByPipe]
})
export class SharedCommonModule {}
