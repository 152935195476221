import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire';
import { FlexLayoutModule } from '@angular/flex-layout';
import { environment } from '../environments/environment';
import { FirebaseInterceptor } from './services/auth/firebase.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreateSegmentConfirmationComponent } from './components/segment/create-segment-confirmation/create-segment-confirmation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AccessGuard } from './services/auth/access-guard';
import { FooterComponent } from './components/footer/footer.component';
import { SharedCommonModule } from './shared/common.module';
import { AdminGuard } from './services/auth/admin-guard';
import { ClipboardModule } from '@angular/cdk/clipboard';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const materialModules = [MatIconModule, MatButtonModule, MatButtonToggleModule, MatTabsModule, MatProgressSpinnerModule, MatDialogModule, MatSnackBarModule];

@NgModule({
  declarations: [AppComponent, ErrorDialogComponent, InfoDialogComponent, CreateSegmentConfirmationComponent, FooterComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ...materialModules,
    FlexLayoutModule,
    SharedCommonModule,
    ClipboardModule
  ],
  exports: [],
  providers: [
    {
      provide: FIREBASE_OPTIONS,
      useValue: environment.firebaseConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FirebaseInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: AccessGuard },
    { provide: AdminGuard }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
