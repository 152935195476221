import { AbstractDataService } from './abstract-data.service';
import { Campaign } from '../../model/data-services';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const REST_ENDPOINT_URI = 'campaigns/';

@Injectable({
  providedIn: 'root'
})
export class CampaignDataService extends AbstractDataService<Campaign> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }
}
