import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SegmentChangeHistory, SegmentRequest, SegmentResponse, StandardSegment } from '../../model/data-services';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TargetingStrategyEnum } from '../../model/targeting';
import * as moment from 'moment';
import { dateFormat, serverDateFormat } from 'src/app/shared/constants';
import API from 'src/app/model/API';
import { PostProcessingSegmentsEnum } from 'src/app/model/standard-segment';

@Injectable({
  providedIn: 'root'
})
export class SegmentService {
  headers = new HttpHeaders({
    accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  });

  private _atvHouseholdSegment: StandardSegment[] = [];

  constructor(private http: HttpClient) {}

  public saveSegment(segment: SegmentRequest): Observable<SegmentResponse[]> {
    return this.http.post<SegmentResponse[]>(environment.serverUrl + API.SEGMENT_WRITE, segment, { headers: this.headers }).pipe(take(1));
  }

  public updateSegment(segment: SegmentResponse): Observable<SegmentResponse[]> {
    segment.segmentName = this.getSegmentName(segment);
    return this.http.post<SegmentResponse[]>(environment.serverUrl + API.SEGMENT_UPDATE, segment, { headers: this.headers }).pipe(take(1));
  }

  public getSegmentChangeHistory(segmentName: string): Observable<SegmentChangeHistory[]> {
    return this.http.get<SegmentChangeHistory[]>(environment.serverUrl + API.SEGMENT_HISTORY + '?q=' + segmentName, { headers: this.headers }).pipe(take(1));
  }

  public getSegments(): Observable<SegmentResponse[]> {
    return this.http
      .get<SegmentResponse[]>(environment.serverUrl + API.SEGMENT_READ, {
        headers: this.headers
      })
      .pipe(take(1));
  }

  public getTargetingStrategy(type: string): TargetingStrategyEnum {
    if (type === 'fre') {
      return TargetingStrategyEnum.FREQUENCY;
    }
    if (type === 'con') {
      return TargetingStrategyEnum.REMINDER;
    }
    return TargetingStrategyEnum.INCREMENTAL;
  }

  public getState(segment: SegmentResponse): string {
    const checkDateFormat = moment(segment.retargetingCampaignEnd, serverDateFormat, true).isValid() ? serverDateFormat : dateFormat;
    if (moment().isBefore(moment(segment.retargetingCampaignStart, checkDateFormat))) {
      return 'Geplant';
    }
    if (moment().isAfter(moment(segment.retargetingCampaignEnd, checkDateFormat))) {
      return 'Ausgelaufen';
    }
    return 'Aktiv';
  }

  public calculateFirstCampaignStart(spotStartDate: moment.Moment | null, retargetingStartDate: moment.Moment): moment.Moment {
    if (spotStartDate === null) {
      return moment().subtract(7, 'days');
    }
    const adjustedStartDate = retargetingStartDate;
    adjustedStartDate.subtract(90, 'days');
    if (adjustedStartDate.isBefore(spotStartDate)) {
      return spotStartDate;
    }
    return adjustedStartDate;
  }

  public getSegmentName(segment: SegmentResponse): string {
    if (!segment.frequencyId) {
      return segment.segmentName;
    }
    return segment.segmentName.slice(0, segment.segmentName.length - 4);
  }

  setAtvHouseholdSegment(segment: StandardSegment): void {
    this._atvHouseholdSegment.push(segment);
  }

  getAtvHouseholdSegment(segmentId: PostProcessingSegmentsEnum): boolean {
    return this._atvHouseholdSegment.some((segment) => segment.id === segmentId);
  }
}
