import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ROLE_ADMIN, ROLE_CAMPAIGN_MANAGER, ROLE_CLIENT_SERVICES, ROLE_DATA_OPERATIONS, ROLE_GUEST } from 'src/app/shared/constants';
import { UserDataService } from '../data/user-data-service';
@Injectable()
export class AccessGuard implements CanActivate {
  constructor(private router: Router, private userDataService: UserDataService) {}

  checkRoles = [ROLE_ADMIN, ROLE_DATA_OPERATIONS, ROLE_CAMPAIGN_MANAGER, ROLE_CLIENT_SERVICES, ROLE_GUEST];

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.userDataService.data.pipe(
      map((user) => {
        const canActivate = this.checkRoles.some((role) => {
          return Array.isArray(user?.roles) ? user.roles.includes(role) : false;
        });
        if (!canActivate) {
          this.router.navigate(['/unauthorized']);
        }
        return canActivate;
      }),
      catchError((error) => {
        this.router.navigate(['/unauthorized']);
        return of(false);
      })
    );
  }
}
