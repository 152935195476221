import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { shareReplay } from 'rxjs/operators';

export abstract class AbstractSingleDataService<T> {
  protected readonly restEndpointUrl: string;
  public data: Observable<T>;

  protected constructor(protected http: HttpClient, protected restEndpointUri: string) {
    this.restEndpointUrl = environment.serverUrl + restEndpointUri;
    this.getData();
  }

  private getData() {
    const headers = new HttpHeaders({
      accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    this.data = this.http.get<T>(this.restEndpointUrl, { headers }).pipe(shareReplay());
  }
}
